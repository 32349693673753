<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>From date</label>
          <b-form-datepicker
            id="example-datepicker"
            v-model="dateFromFilter"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit'}"
            locale="en"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>To date</label>
          <b-form-datepicker
            id="example-datepicker2"
            v-model="dateToFilter"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Wallet Summary
        </h5>
      </b-card-header>
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".28"
        rounded="sm"
      >
        <b-table-simple
          hover
          striped
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Count</b-th>
              <b-th>MRP [MYR]</b-th>
              <b-th>Amount [MYR]</b-th>
              <b-th>Type of Transaction</b-th>
              <b-th>Type</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="list.length>0">
              <b-tr
                v-for="(tr, indextr) in list"
                :key="indextr"
              >
                <b-td>
                  {{ tr.Rows }}
                </b-td>
                <b-td>{{ tr.org_amt }}</b-td>
                <b-td>{{ tr.amt }}</b-td>
                <b-td>{{ tr.maintype }}</b-td>
                <b-td>
                  <b-badge
                    v-if="tr.type==&quot;D&quot;"
                    :variant="getOrderStatusColor(tr.type)"
                  >
                    Debited
                  </b-badge>
                  <b-badge
                    v-if="tr.type==&quot;C&quot;"
                    :variant="getOrderStatusColor(tr.type)"
                  >
                    Credited
                  </b-badge>
                </b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr rowspan="10">
                <b-td
                  colspan="5"
                  class="text-center"
                >
                  No data avilable

                </b-td>
              </b-tr>
            </template>
          </b-tbody>

        </b-table-simple><br>
        <b-table-simple
          hover
          striped
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Opening Balance in {{ dateFromFilter }}</b-th>
              <b-th>Total Credited in {{ dateFromFilter }}:{{ dateToFilter }}</b-th>
              <b-th>Total Debited in {{ dateFromFilter }}:{{ dateToFilter }}</b-th>
              <b-th>Closing Balance in {{ dateToFilter }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="list.length>0">
              <b-tr>
                <b-td>
                  {{ openBalnace }}
                </b-td>
                <b-td>{{ totalCredited }}</b-td>
                <b-td>{{ totalDebited }}</b-td>
                <b-td>{{ closingBalance }}</b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr rowspan="10">
                <b-td
                  colspan="4"
                  class="text-center"
                >
                  No data avilable

                </b-td>
              </b-tr>
            </template>
          </b-tbody>

        </b-table-simple>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BCardBody,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import UserReportService from '../../services/user.report.service'
import OperatorService from '../../services/operator.service'
import JwtService from '../../common/jwt.service'

export default {
  components: {
    BTableSimple,
    BFormDatepicker,
    BCard,
    BCardHeader,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BBadge,
    BPagination,
    BOverlay,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  data() {
    const date = new Date()
    const lastDay = new Date(date.getFullYear(), (date.getMonth() + 1), 0)
    return {

      dateFromFilter: `${date.getFullYear()}-${date.getMonth() + 1}-` + '01',
      dateToFilter: `${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}`,
      configdateTimePicker: {
        dateFormat: 'Y-m-d',
      },
      date: '',
      selected: [],
      status: 'All',
      itemsPerPage: 25,
      isMounted: false,
      showLoading: false,
      summary: [],
      openBalnace: '',
      closingBalance: '',
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    list() {
      return this.summary
    },
    queriedItems() {
      return this.totalItems
    },
    totalCredited() {
      return this.summary.reduce((total, item) => {
        if (item.type == 'C') {
          return total + parseInt(item.org_amt)
        }
        return total
      }, 0)
    },
    totalDebited() {
      return this.summary.reduce((total, item) => {
        if (item.type == 'D') {
          return total + parseInt(item.org_amt)
        }
        return total
      }, 0)
    },
  },
  mounted() {
    this.isMounted = true
    this.getWalletSummary()
  },
  methods: {
    getOrderStatusColor(status) {
      if (status === 'C') return 'light-success'
      if (status === 'D') return 'light-danger'
      return 'success'
    },
    changeLenght(data) {
      this.itemsPerPage = data
      this.getWalletSummary({
        limit: this.itemsPerPage,
      })
    },
    handleSearch() {
      if (this.dateFromFilter && this.dateToFilter) {
        this.date = `${this.dateFromFilter}:${this.dateToFilter}`
      }
      this.getWalletSummary({
        date: this.date,
      })
    },
    handleChangePage(page) {
      this.getWalletSummary({
        page: this.userData.current_page,
        date: this.date,
      })
    },
    handleSort(key, active) {},
    resetColFilters() {
      const date = new Date()
      const lastDay = new Date(date.getFullYear(), (date.getMonth() + 1), 0)
      this.dateFromFilter = `${date.getFullYear()}-${date.getMonth() + 1}-` + '01',
      this.dateToFilter = `${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}`,
      this.getWalletSummary()
      this.showLoading = false
    },
    getWalletSummary(data) {
      this.showLoading = true
      UserReportService.getWalletBalanceSummary(data).then(
        response => {
          this.summary = response.walletBalance
          this.openBalnace = response.openBalnce
          this.closingBalance = response.closingBalance
          this.showLoading = false
        },
        error => {
          this.showLoading = false
        },
      )
    },
  },

};

</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
